<template>
    <div class="GenCodeCard">
        <el-form ref="formRef" :model="form" :rules="rules" label-width="140px">
            <el-row>
                <el-col :span="11">
                    <el-form-item label="是否单据">
                        <el-select v-model="form.billFlag" placeholder="请选择是否单据" style="width: 100%;" @change="billFlagChange">
                            <el-option label="否" value="0"></el-option>
                            <el-option label="是" value="1"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="11" :offset="2">
                    <el-form-item label="单据模块">
                        <el-cascader :props="{checkStrictly: false}" v-model="form.billModel" :disabled="form.billFlag==0" :clearable="false"
                                     placeholder="请选择单据模块" style="width:100%" :options="billTypeTreeData" @change="billTypeChange"/>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row>
                <el-col :span="11">
                    <el-form-item label="主表名称" prop="tbName">
                        <el-cascader :ref="el=>refMap.set('table',el)" :props="{emitPath:false}" v-model="form.tbName" placeholder="请选择主表名称" style="width:100%" :options="mainTbTreeData" @change="change">
                            <template #default="{ node, data }">
                                <span>{{ data.label }}</span>
                                <span v-if="data.tbName">({{ data.tbName}})</span>
                            </template>
                        </el-cascader>
                    </el-form-item>
                </el-col>

                <el-col :span="11" :offset="2">
                    <el-form-item label="模块名称" prop="modelName">
                        <el-input v-model="form.modelName" maxlength="50" :disabled="form.billFlag==1" placeholder="请输入模块名称" clearable/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="11">
                    <el-form-item label="模块描述" prop="modelCaption">
                        <el-input v-model="form.modelCaption" maxlength="50" placeholder="请输入模块描述" clearable/>
                    </el-form-item>
                </el-col>

                <el-col :span="11" :offset="2">
                    <el-form-item label="显示列数">
                        <el-select v-model="form.cardCol" placeholder="请选择显示列数" style="width: 100%;">
                            <el-option label="一列" value="1"></el-option>
                            <el-option label="两列" value="2"></el-option>
                            <el-option label="三列" value="3"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-form-item label="后台代码存放路径" prop="javaPath">
                <el-input v-model="form.javaPath" maxlength="180" placeholder="请把路径设置到项目路径下,比如java、src" clearable/>
            </el-form-item>
            <el-form-item label="前代码存放路径" prop="vuePath">
                <el-input v-model="form.vuePath" maxlength="180" placeholder="请输入前代码存放路径" clearable/>
            </el-form-item>
        </el-form>

        <el-tabs type="border-card">
            <el-tab-pane label="关联子表">
                <EditTable :ref="el=>refMap.set('childTable',el)" v-bind="childTableDetailParam">
                    <template v-slot:tbCols>
                        <el-table-column prop="F_TABLE" label="子表名称">
                            <template #default="scope">
                                <span style="margin-left: 10px" v-if="!scope.row.EDIT">{{ scope.row.F_TABLE }}</span>
                                <el-cascader v-else :ref="el=>refMap.set('tableDetail',el)" :props="{emitPath:false }" v-model="scope.row.F_TABLE" :options="mainTbTreeData" @change="tbDetailChange(scope.row)"/>
                            </template>
                        </el-table-column>
                        <el-table-column prop="F_MODEL_NAME" label="模块名称">
                            <template #default="scope">
                                <span style="margin-left: 10px" v-if="!scope.row.EDIT">{{ scope.row.F_MODEL_NAME }}</span>
                                <el-input v-else placeholder="请输入内容" v-model="scope.row.F_MODEL_NAME" maxlength="20" clearable/>
                            </template>
                        </el-table-column>
                        <el-table-column label="主表关联字段" prop="F_FIELD">
                            <template #default="scope">
                                <span v-if="!scope.row.EDIT">{{ scope.row.F_FIELD }}</span>
                                <ComboGrid v-else :ref="el=>refMap.set('fieldCombo',el)" v-model="scope.row.F_FIELD" v-bind="fieldComboParam(scope.row.F_TABLE)"/>
                            </template>
                        </el-table-column>
                    </template>
                </EditTable>
            </el-tab-pane>

            <el-tab-pane label="列表查询字段">
                <EditTable :ref="el=>refMap.set('queryFields',el)" v-bind="queryFieldsDetailParam">
                    <template v-slot:tbCols>
                        <el-table-column label="查询字段" prop="F_FIELD"/>
                        <el-table-column label="标题" prop="F_FIELD_CAPTION">
                            <template #default="scope">
                                <span style="margin-left: 10px" v-if="!scope.row.EDIT">{{ scope.row.F_FIELD_CAPTION }}</span>
                                <el-input v-else placeholder="请输入内容" v-model="scope.row.F_FIELD_CAPTION" maxlength="20" clearable/>
                            </template>
                        </el-table-column>
                        <el-table-column label="字段类型" prop="F_FIELD_TYPE">
                            <template #default="scope">
                                <span v-if="!scope.row.EDIT">{{ scope.row.F_FIELD_TYPE }}</span>
                                <el-select v-else v-model="scope.row.F_FIELD_TYPE" placeholder="请选择">
                                    <el-option v-for="item in fieldType" :key="item.value" :label="item.label" :value="item.value"/>
                                </el-select>
                            </template>
                        </el-table-column>
                    </template>
                </EditTable>
            </el-tab-pane>

            <el-tab-pane label="列表显示字段">
                <EditTable :ref="el=>refMap.set('listFields',el)" v-bind="listFieldsDetailParam">
                    <template v-slot:tbCols>
                        <el-table-column label="字段" prop="F_FIELD"/>
                        <el-table-column label="标题" prop="F_FIELD_CAPTION">
                            <template #default="scope">
                                <span style="margin-left: 10px" v-if="!scope.row.EDIT">{{ scope.row.F_FIELD_CAPTION }}</span>
                                <el-input v-else placeholder="请输入内容" v-model="scope.row.F_FIELD_CAPTION" maxlength="20" clearable/>
                            </template>
                        </el-table-column>

                        <el-table-column label="头对齐方式" prop="F_TITLE_ALIGN">
                            <template #default="scope">
                                <span v-if="!scope.row.EDIT">{{ scope.row.F_TITLE_ALIGN }}</span>
                                <el-select v-else v-model="scope.row.F_TITLE_ALIGN" placeholder="请选择">
                                    <el-option v-for="item in alignType" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                </el-select>
                            </template>
                        </el-table-column>
                        <el-table-column label="值对齐方式" prop="F_VALUE_ALIGN">
                            <template #default="scope">
                                <span v-if="!scope.row.EDIT">{{ scope.row.F_VALUE_ALIGN }}</span>
                                <el-select v-else v-model="scope.row.F_VALUE_ALIGN" placeholder="请选择">
                                    <el-option v-for="item in alignType" :key="item.value" :label="item.label" :value="item.value"/>
                                </el-select>
                            </template>
                        </el-table-column>
                    </template>
                </EditTable>
            </el-tab-pane>

            <el-tab-pane label="卡片显示字段">
                <EditTable :ref="el=>refMap.set('cardFields',el)" v-bind="cardFieldsDetailParam">
                    <template v-slot:tbCols>
                        <el-table-column label="字段" prop="F_FIELD"/>
                        <el-table-column label="标题" prop="F_FIELD_CAPTION">
                            <template #default="scope">
                                <span style="margin-left: 10px" v-if="!scope.row.EDIT">{{ scope.row.F_FIELD_CAPTION }}</span>
                                <el-input v-else placeholder="请输入内容" v-model="scope.row.F_FIELD_CAPTION" maxlength="20" clearable/>
                            </template>
                        </el-table-column>
                        <el-table-column label="是否必填" prop="F_REQUIRED">
                            <template #default="scope">
                                <span v-if="!scope.row.EDIT">{{ scope.row.F_REQUIRED_NAME }}</span>
                                <el-select v-else v-model="scope.row.F_REQUIRED" :ref="el=>refMap.set('cardFieldRequired',el)">
                                    <el-option v-for="item in yesNoSel" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                </el-select>
                            </template>
                        </el-table-column>
                        <el-table-column label="字段类型" prop="F_FIELD_TYPE">
                            <template #default="scope">
                                <span v-if="!scope.row.EDIT">{{ scope.row.F_FIELD_TYPE }}</span>
                                <el-select v-else v-model="scope.row.F_FIELD_TYPE" placeholder="请选择">
                                    <el-option v-for="item in cardFieldType" :key="item.value" :label="item.label" :value="item.value"/>
                                </el-select>
                            </template>
                        </el-table-column>
                    </template>
                </EditTable>
            </el-tab-pane>


            <el-tab-pane v-for="(item, index) in cardDetailGridArr" :label="item.tbCaption" :key="item.tbName">
                <EditTable :ref="el=>refMap.set(item.tbName,el)" v-bind="cardDetailFieldsDetailParam(item.tbName)">
                    <template v-slot:tbCols>
                        <el-table-column label="字段" prop="F_FIELD"/>
                        <el-table-column label="标题" prop="F_FIELD_CAPTION">
                            <template #default="scope">
                                <span v-if="!scope.row.EDIT" style="margin-left: 10px">{{ scope.row.F_FIELD_CAPTION }}</span>
                                <el-input v-else placeholder="请输入内容" v-model="scope.row.F_FIELD_CAPTION" maxlength="20" clearable/>
                            </template>
                        </el-table-column>

                        <el-table-column label="字段类型" prop="F_FIELD_TYPE">
                            <template #default="scope">
                                <span v-if="!scope.row.EDIT">{{ scope.row.F_FIELD_TYPE }}</span>
                                <el-select v-else v-model="scope.row.F_FIELD_TYPE" placeholder="请选择">
                                    <el-option v-for="item in fieldType" :key="item.value" :label="item.label" :value="item.value"/>
                                </el-select>
                            </template>
                        </el-table-column>

                        <el-table-column label="头对齐方式" prop="F_TITLE_ALIGN">
                            <template #default="scope">
                                <span v-if="!scope.row.EDIT">{{ scope.row.F_TITLE_ALIGN }}</span>
                                <el-select v-else v-model="scope.row.F_TITLE_ALIGN" placeholder="请选择">
                                    <el-option v-for="item in alignType" :key="item.value" :label="item.label" :value="item.value"/>
                                </el-select>
                            </template>
                        </el-table-column>
                        <el-table-column label="值对齐方式" prop="F_VALUE_ALIGN">
                            <template #default="scope">
                                <span v-if="!scope.row.EDIT">{{ scope.row.F_VALUE_ALIGN }}</span>
                                <el-select v-else v-model="scope.row.F_VALUE_ALIGN" placeholder="请选择">
                                    <el-option v-for="item in alignType" :key="item.value" :label="item.label" :value="item.value"/>
                                </el-select>
                            </template>
                        </el-table-column>
                    </template>
                </EditTable>
            </el-tab-pane>

        </el-tabs>

        <!-- 字段穿梭框 -->
        <el-dialog title="字段选择" v-model="dialogVisible" width="60%" append-to-body>
            <el-transfer filterable v-model="transformRightData" :data="transformLeftData" :titles="titles"/>
            <template #footer>
                <span class="dialog-footer">
                    <el-button type="primary" @click="chooseFieldsSureHandler">确 定</el-button>
                </span>
            </template>
        </el-dialog>

        <!-- 代码生成选择框 -->
        <el-dialog title="生成种类选择" v-model="chooseTypeVisible" width="35%" append-to-body class="genCodeType">

            <el-divider content-position="left">后端</el-divider>
            <el-row class="myRow">
                <el-col :span="6">controller:</el-col>
                <el-col :span="6">
                    <el-switch v-model="genTypeForm.controller"/>
                </el-col>
                <el-col :span="6">helper:</el-col>
                <el-col :span="6">
                    <el-switch v-model="genTypeForm.helper"/>
                </el-col>
            </el-row>
            <el-row class="myRow">
                <el-col :span="6">bean:</el-col>
                <el-col :span="6">
                    <el-switch v-model="genTypeForm.bean"/>
                </el-col>
                <el-col :span="6">dao:</el-col>
                <el-col :span="6">
                    <el-switch v-model="genTypeForm.dao"/>
                </el-col>
            </el-row>
            <el-row class="myRow" v-show="genTypeForm.showDetail">
                <el-col :span="6">detailBean:</el-col>
                <el-col :span="6">
                    <el-switch v-model="genTypeForm.detailBean"/>
                </el-col>
                <el-col :span="6">detailDao:</el-col>
                <el-col :span="6">
                    <el-switch v-model="genTypeForm.detailDao"/>
                </el-col>
            </el-row>
            <el-row class="myRow">
                <el-col :span="6">buffer:</el-col>
                <el-col :span="6">
                    <el-switch v-model="genTypeForm.buffer"/>
                </el-col>
            </el-row>
            <el-divider content-position="left">前端</el-divider>
            <el-row class="myRow">
                <el-col :span="6">card:</el-col>
                <el-col :span="6">
                    <el-switch v-model="genTypeForm.card"/>
                </el-col>
                <el-col :span="6">cardHelper:</el-col>
                <el-col :span="6">
                    <el-switch v-model="genTypeForm.cardHelper"/>
                </el-col>
            </el-row>
            <el-row class="myRow">
                <el-col :span="6">list:</el-col>
                <el-col :span="6">
                    <el-switch v-model="genTypeForm.list"/>
                </el-col>
                <el-col :span="6">report:</el-col>
                <el-col :span="6">
                    <el-switch v-model="genTypeForm.report"/>
                </el-col>
            </el-row>
            <el-row class="myRow">
                <el-col :span="6">地图:</el-col>
                <el-col :span="6">
                    <el-switch v-model="genTypeForm.bmap"/>
                </el-col>
            </el-row>
            <template #footer>
                <span class="dialog-footer">
                    <el-button type="primary" @click="chooseTypeSureHandler">确 定</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>


<script>
    import GenCodeCardHelper from "./GenCodeCardHelper.js";

    export default GenCodeCardHelper;
</script>

<style scoped>
.GenCodeCard{width: 100%;}
</style>
